* {

  scrollbar-width: thin;

  scrollbar-color: #f1f1f1;

}



::-webkit-scrollbar {

  width: 7px;

  height: 7px;

  border-radius: 3px;

}



::-webkit-scrollbar-track {

  background: #999933;

  border-radius: 3px;

}



::-webkit-scrollbar-thumb {

  background: #999933b7;

  border-radius: 3px;

}
.back{
    background-image: url(../components/assets/FOTOS/FOTO3.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 600px;
    
}
.Fonts {
    &.Titles{
        font-family: 'Encabezados';
    }
    &.Text{
        font-family: 'Textos';
    }
    &.Menu{
        font-family: 'Encabezados';
        font-size: 1.25em;
    color: white;
    cursor: pointer;
    }
    &.Menu:hover{
      color: #999933,
    }
    
}
.Inputs{
    border: 2px solid #999933;
    border-radius: 4px;
    background-color: #fff;
    color:#999933;
    width: 100%;
    font-size: 16px;
    font-family: 'Textos';
    height: 28px;
}
.Inputs:hover{
    box-shadow: #999933b7 0 0 0 0.2rem;
}
.Inputs:focus{
    border: 2px solid #999933;
    border-radius: 4px;
}
.Inputs:target{
    border: 2px solid #999933;
    border-radius: 4px;
}


.container-box {
  cursor: pointer;
  width: 100%;
  min-height: max-content;
  .box-cards {
    margin: auto;
    margin-top: 50px;
    .single-card {
      max-width: 25%;
      min-width: 25%;
      display: inline-block;
      .carta-box {
        margin: 16px;
        width: 150px;
        height: 150px;
        display: inline-block;
        perspective: 1000px;
      }

      .carta-box:hover .carta {
        transform: rotateY(180deg);
      }

      .carta {
        transform-style: preserve-3d;
        transition: all 0.5s linear;
      }

      .cara {
        position: absolute;
        backface-visibility: hidden;
      }

      .cara.detras {
        
        transform: rotateY(180deg);
        .TextServices{
            text-align: center;
            color:#999933;
            font-family: 'Encabezados';
        }
      }
    }}
}
